.modelView {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modalSize {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative !important;
  overflow-y: auto;
  background-color: #fff !important;
  border-radius: 5px !important;
  max-height: 90%;
}

.iconView {
  cursor: pointer !important;
  position: absolute !important;
  top: 15px !important;
  right: 15px !important;
  background-color: #eeeff1 !important;
  display: flex!important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 5px !important;
  height: 25px !important;
  width: 25px !important;
}

.titleText {
  text-align: center !important;
  font-family: IBMPlexSansBold !important;
  font-size: 23px !important;
  color: #516377 !important;
}

.modalSize:focus {
  outline: none !important;
}

.modalInitialSize {
  transition: transform 0.15s ease-out !important;
  transform: translateY(-100px) scale(0) !important;
}

.modalVisibleSize {
  transition: transform 0.15s ease-out !important;
  transform: translateY(0) scale(1) !important;
}

.btnView {
  display: flex;
}

.reasonTitle {
  text-align: center !important;
  font-family: IBMPlexSansMedium !important;
  font-size: 0.9375rem !important;
  color: #677788 !important;
}

.reasonValue {
  text-align: center !important;
  font-family: IBMPlexSansRegular !important;
  font-size: 0.9375rem !important;
  color: #677788 !important;
}
