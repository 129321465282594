.cardMainView {
    height: 100vh !important;
    position: relative !important;
}

.boxLeftView {
    position: absolute !important;
    top: -110px !important;
    left: -110px !important;
    height: 350px;
    width: 350px;
}

.boxRightView {
    position: absolute !important;
    bottom: 6px !important;
    right: 0 !important;
    height: 220px;
    width: 110px;
}

.imgStyle {
    height: 100%;
    width: 100%
}

.cardView {
    background-color: #FFFFFF !important;
    border-radius: 2px;
    padding: 25px;
    z-index: 1000;
    box-shadow: 0 2px 14px rgba(38, 60, 85, 0.16);
}

.logoView {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-bottom: 30px;
}

.subTitleText {
    color: #555 !important;
    font-family: RubikSemiBold !important;
    font-size: 20px !important;
}

.contentText {
    color: #888 !important;
    font-family: IBMPlexSansRegular !important;
    font-size: 15px !important;
}

.passwordForgotView {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    margin-bottom: 6px !important;
}

.passwordLabelText {
    font-family: IBMPlexSansRegular !important;
    color: #888 !important;
    font-size: 12px !important;
}

.forgotPasswordText {
    cursor: pointer;
    font-family: IBMPlexSansRegular !important;
    color: #5a8dee !important;
    font-size: 13px !important;
}

.rememberView {
    cursor: pointer;
}

.rememberText {
    font-family: IBMPlexSansRegular !important;
    color: #888 !important;
    font-size: 14px !important;
}

.accountMainView {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 15px
}

.platformText {
    font-family: IBMPlexSansRegular !important;
    color: #888 !important;
    font-size: 15px !important;
}

.accountView {
    margin-left: 5px;
}

.accountText {
    cursor: pointer;
    font-family: IBMPlexSansRegular !important;
    color: #5a8dee !important;
    font-size: 15px !important;
}

