.labelView {
  display: flex;
  flex-wrap: wrap;
}

.labelText {
  text-align: center !important;
  color: #fff !important;
  font-family: IBMPlexSansSemiBold !important;
  font-size: 9px !important;
  height: 16px !important;
  border-radius: 3px !important;
  cursor: pointer !important;
}
