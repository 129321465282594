.modelView {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modalSize {
  display: flex;
  justify-content: space-between;
  position: relative !important;
  overflow-y: auto;
  background-color: #fff !important;
  border-radius: 5px !important;
  max-height: 90%;
}

.iconView {
  cursor: pointer !important;
  position: absolute !important;
  top: 15px !important;
  right: 15px !important;
  background-color: #eeeff1 !important;
  display: flex!important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 5px !important;
  height: 25px !important;
  width: 25px !important;
}

.titleText {
  font-family: RubikSemiBold !important;
  text-align: center !important;
  font-size: 23px !important;
  color: #516377 !important;
}

.modalSize:focus {
  outline: none !important;
}

.modalInitialSize {
  transition: transform 0.15s ease-out !important;
  transform: translateY(-100px) scale(0) !important;
}

.modalVisibleSize {
  transition: transform 0.15s ease-out !important;
  transform: translateY(0) scale(1) !important;
}

.nameText {
  font-family: RubikSemiBold !important;
  font-size: 16px !important;
  color: #516377 !important;
}

.ageText,
.mobileText,
.whatsappText,
.emailText {
  font-family: RubikLight !important;
  font-size: 13px !important;
  color: #516377 !important;
}

.addressText {
  font-family: RubikLight !important;
  font-size: 13px !important;
  color: #888 !important;
}

.referredText {
  font-family: RubikLight !important;
  font-size: 13px !important;
  color: #888 !important;
}
