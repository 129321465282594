.cardView {
    background-color: #ffffff !important;
    border-radius: 2px;
    z-index: 1000;
    box-shadow: 0 2px 14px rgba(38, 60, 85, 0.16);
}

.labelText {
    font-family: IBMPlexSansRegular !important;
    color: #000 !important;
    font-size: 15px !important;
    margin-bottom: 6px !important;
}

.averageText {
    font-family: IBMPlexSansRegular !important;
    color: #000 !important;
    font-size: 15px !important;
}

.averageEqText {
    font-family: IBMPlexSansRegular !important;
    color: #000 !important;
    font-size: 15px !important;
}

.rightSymbol {
    color: red !important;
    font-size: 15px !important;
}

.leftSymbol {
    color: blue !important;
    font-size: 15px !important;
}
