.labelText {
  font-family: IBMPlexSansRegular !important;
  color: #888 !important;
  font-size: 12px !important;
  margin-bottom: 6px !important;
}

.errorBorderStyle {
  border: 1px solid red;
}

.borderStyle {
  border: 1px solid #ccc;
}

.errorView {
}

.errorText {
  color: red !important;
  font-size: 12px !important;
  font-family: IBMPlexSansRegular !important;
}
