.imageUploadView {
    cursor: pointer !important;
    border: 1px dashed #ccc !important;
    min-height: 200px !important;
}

.dataCenterView {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.uploadMainImgView {
    position: relative !important;
    width: fit-content !important;
}

.uploadImgView {
    overflow: hidden !important;
    border: 3px solid #FFF !important;
    border-radius: 3px !important;
    box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075) !important;
    height: 150px !important;
    width: 150px !important;
}

.uploadImgStyle {
    min-height: 100px !important;
    width: 100px !important;
    padding: 5px !important;
}

.borderBottomLine {
    border: 0.2px solid #ddd !important;
    width: 100% !important
}

.removeFileText {
    font-size: 12px !important;
    color: #aaa !important;
}

.dropFileText {
    font-size: 15px !important;
    color: #aaa !important;
    text-align: center !important;
}

.errorView {
}

.errorText {
    color: red !important;
    font-size: 12px !important;
}
