.cardView {
  background-color: #ffffff !important;
  border-radius: 2px;
  z-index: 1000;
  box-shadow: 0 2px 14px rgba(38, 60, 85, 0.16);
}

.tableView {
  overflow-x: auto !important;
}

.statusView {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #dff9ec !important;
  text-transform: uppercase;
  padding-right: 7px !important;
  padding-left: 7px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  border-radius: 2px !important;
  line-height: 0.92;
  width: 80px !important;
}

.statusText {
  font-family: IBMPlexSansBold !important;
  color: #39da8a !important;
  font-size: 10px !important;
}

.iconMainView {
  display: flex !important;
  flex-direction: row !important;
}

.iconView {
  cursor: pointer !important;
}

.viewText {
  font-family: IBMPlexSansRegular !important;
  color: #516377 !important;
  font-size: 14px !important;
}

.showingEntriesText {
  font-family: IBMPlexSansRegular !important;
  color: #a8b1bb !important;
  font-size: 0.95rem !important;
}

.searchFilterText {
  font-family: IBMPlexSansBold !important;
  text-transform: none !important;
  color: #516377 !important;
  font-size: 1rem !important;
}

.noMatchFoundText {
  font-family: IBMPlexSansRegular !important;
  color: #516377 !important;
  font-size: 15px !important;
}

.tableRowView {
  cursor: pointer !important;
}
