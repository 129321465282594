@font-face {
  font-family: "RubikBlack";
  src: url("../fonts/Rubik/Rubik-Black.ttf");
}

@font-face {
  font-family: "RubikBold";
  src: url("../fonts/Rubik/Rubik-Bold.ttf");
}

@font-face {
  font-family: "RubikLight";
  src: url("../fonts/Rubik/Rubik-Light.ttf");
}

@font-face {
  font-family: "RubikMedium";
  src: url("../fonts/Rubik/Rubik-Medium.ttf");
}

@font-face {
  font-family: "RubikRegular";
  src: url("../fonts/Rubik/Rubik-Regular.ttf");
}

@font-face {
  font-family: "RubikSemiBold";
  src: url("../fonts/Rubik/Rubik-SemiBold.ttf");
}

@font-face {
  font-family: "IBMPlexSansBold";
  src: url("../fonts/IBMPlexSans/IBMPlexSans-Bold.ttf");
}

@font-face {
  font-family: "IBMPlexSansLight";
  src: url("../fonts/IBMPlexSans/IBMPlexSans-Light.ttf");
}

@font-face {
  font-family: "IBMPlexSansExtraLight";
  src: url("../fonts/IBMPlexSans/IBMPlexSans-ExtraLight.ttf");
}

@font-face {
  font-family: "IBMPlexSansMedium";
  src: url("../fonts/IBMPlexSans/IBMPlexSans-Medium.ttf");
}

@font-face {
  font-family: "IBMPlexSansRegular";
  src: url("../fonts/IBMPlexSans/IBMPlexSans-Regular.ttf");
}

@font-face {
  font-family: "IBMPlexSansSemiBold";
  src: url("../fonts/IBMPlexSans/IBMPlexSans-SemiBold.ttf");
}

@font-face {
  font-family: "IBMPlexSansThin";
  src: url("../fonts/IBMPlexSans/IBMPlexSans-Thin.ttf");
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0 !important;
  overflow: visible !important;
  padding-right: 0 !important;
  background-color: #f3f4f4 !important;
}

.MuiAppBar-root {
  padding-right: 0 !important;
}

#dashboardDiv .MuiPaper-elevation,
#appointmentDiv {
  box-shadow: 0 2px 14px rgba(38, 60, 85, 0.16) !important;
}

thead .MuiTableCell-root {
  text-transform: uppercase !important;
  font-family: IBMPlexSansBold !important;
  color: #516377 !important;
  font-size: 0.75rem !important;
  letter-spacing: 1px !important;
}

tbody .MuiTableCell-root {
  color: #677788 !important;
  font-size: 0.85rem !important;
  font-family: IBMPlexSansRegular !important;
}

.MuiPickersLayout-root ul li,
.MuiPickersLayout-root button,
.MuiDateCalendar-root button,
.MuiDateCalendar-root .MuiTypography-root,
.MuiDateCalendar-root .MuiPickersCalendarHeader-label {
  font-family: IBMPlexSansRegular !important;
}

.MuiAutocomplete-noOptions {
  font-family: IBMPlexSansRegular !important;
}

.audio-graph-table .MuiTableCell-root {
  padding: 3px 3px !important;
  line-height: 14px !important;
  border: 0!important;
}

.audio-graph-table .MuiTableCell-root:first-child {
  font-family: IBMPlexSansSemiBold !important;
  color: #516377 !important;
}

.audio-graph-table::-webkit-scrollbar {
  height: 5px;
  width: 7px
}

/* Track */
.audio-graph-table::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.audio-graph-table::-webkit-scrollbar-thumb {
  background: #aaaaaa;
}

/* Handle on hover */
.audio-graph-table::-webkit-scrollbar-thumb:hover {
  background: #aaaaaa;
}

.circle-class-val {
  background-image: url("../../assets/img/logo.png");
}
