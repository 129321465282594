.cardView {
  background-color: #ffffff !important;
  border-radius: 6px !important;
  box-shadow: 0 2px 14px rgba(38, 60, 85, 0.16);
}

.detailNameText {
  font-family: RubikSemiBold !important;
  text-align: center !important;
  color: #516377 !important;
  font-size: 18px !important;
  line-height: 1.1 !important;
}

.detailText {
  font-family: RubikSemiBold !important;
  color: #516377 !important;
  font-size: 18px !important;
  line-height: 1.1 !important;
}

.borderView {
  border: 1px solid #d4d8dd !important;
}

.detailLabelText {
  font-family: IBMPlexSansSemiBold !important;
  color: #677788 !important;
  font-size: 14px !important;
}

.detailValueText {
  font-family: IBMPlexSansRegular !important;
  color: #999 !important;
  font-size: 14px !important;
}

.detailValueStatusView {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #dff9ec !important;
  text-transform: uppercase;
  padding-right: 7px !important;
  padding-left: 7px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  border-radius: 2px !important;
  line-height: 0.92;
}

.detailValueStatusText {
  text-align: center !important;
  color: #39da8a !important;
  font-family: IBMPlexSansBold !important;
  font-size: 12px !important;
}
