.modelView {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalSize {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative !important;
    overflow-y: auto;
    background-color: #FFF !important;
    border-radius: 5px !important;
    height: 80%;
}

.iconView {
    cursor: pointer !important;
    position: absolute !important;
    top: 15px !important;
    right: 15px !important;
    background-color: #eeeff1 !important;
    display: flex!important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 5px !important;
    height: 25px !important;
    width: 25px !important;
}

.titleText {
    font-family: RubikSemiBold !important;
    text-align: center !important;
    font-size: 23px !important;
    color: #516377 !important;
}

.modalSize:focus {
    outline: none !important;
}

.modalInitialSize {
    transition: transform 0.15s ease-out !important;
    transform: translateY(-100px) scale(0) !important;
}

.modalVisibleSize {
    transition: transform 0.15s ease-out !important;
    transform: translateY(0) scale(1) !important;
}

.tableView {
    overflow-x: auto !important;
}

.statusBgColor {
    height: 14px !important;
    width: 14px !important;
}

.iconMainView {
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
}

.actionIconView {
    cursor: pointer !important;
}

.noMatchFoundText {
    font-family: IBMPlexSansRegular !important;
    color: #516377 !important;
    font-size: 15px !important;
}
