.statusView {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-transform: uppercase;
  padding-right: 7px !important;
  padding-left: 7px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  border-radius: 2px !important;
  line-height: 0.92;
}

.statusText {
  font-family: IBMPlexSansBold !important;
  font-size: 10px !important;
}

.iconMainView {
  display: flex !important;
  flex-direction: row !important;
}

.iconView {
  cursor: pointer !important;
}

.showingEntriesText {
  color: #a8b1bb !important;
  font-size: 0.95rem !important;
  font-family: IBMPlexSansRegular !important;
}

.noMatchFoundText {
  color: #516377 !important;
  font-size: 15px !important;
  font-family: IBMPlexSansRegular !important;
}
