.showingEntriesText {
  color: #a8b1bb !important;
  font-size: 0.95rem !important;
  font-family: IBMPlexSansRegular !important;
}

.noMatchFoundText {
  color: #516377 !important;
  font-size: 15px !important;
  font-family: IBMPlexSansRegular !important;
}

.iconMainView {
  display: flex !important;
  flex-direction: row !important;
}

.iconView {
  cursor: pointer !important;
}
