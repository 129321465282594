.buttonView {
    background-color: #5a8dee !important;
    font-family: IBMPlexSansMedium !important;
    color: #FFF !important;
}

.button2View {
    background-color: #e7ebef !important;
    font-family: IBMPlexSansMedium !important;
    color: #69809a !important;
}

.btnView {
    text-transform: capitalize !important;
    font-size: 14px !important;
    border-radius: 4px !important;
    line-height: 18px !important;
}

.buttonHoverView:hover {
    background-color: #7ba4f1 !important;
    font-family: IBMPlexSansMedium !important;
    color: #FFF !important;
}

.button2HoverView:hover {
    background-color: #69809a !important;
    font-family: IBMPlexSansMedium !important;
    color: #FFF !important;
}

.button3View {
    background-color: #f3f4f4 !important;
    font-family: IBMPlexSansMedium !important;
    color: #69809a !important;
}

.button3HoverView:hover {
    background-color: #f3f4f4 !important;
    font-family: IBMPlexSansMedium !important;
    color: #69809a !important;
}

.button4View {
    background-color: #ffe5e5 !important;
    font-family: IBMPlexSansMedium !important;
    color: #ff5b5c !important;
}

.button4HoverView:hover {
    background-color: #ff5b5c !important;
    font-family: IBMPlexSansMedium !important;
    color: #ffffff !important;
}

.button5View {
    background-color: #dff9ec !important;
    font-family: IBMPlexSansMedium !important;
    color: #39da8a !important;
}

.button5HoverView:hover {
    background-color: #39da8a !important;
    font-family: IBMPlexSansMedium !important;
    color: #ffffff !important;
}
