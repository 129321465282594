.cardView {
  background-color: #ffffff !important;
  border-radius: 2px;
  z-index: 1000;
  box-shadow: 0 2px 14px rgba(38, 60, 85, 0.16);
}

.tableView {
  overflow-x: auto !important;
}

.iconMainView {
  display: flex !important;
  flex-direction: row !important;
}

.iconView {
  cursor: pointer !important;
}

.viewText {
  font-family: IBMPlexSansRegular !important;
  color: #516377 !important;
  font-size: 14px !important;
}

.showingEntriesText {
  font-family: IBMPlexSansRegular !important;
  color: #a8b1bb !important;
  font-size: 0.95rem !important;
}

.searchFilterText {
  font-family: IBMPlexSansBold !important;
  text-transform: none !important;
  color: #516377 !important;
  font-size: 1rem !important;
}

.noMatchFoundText {
  font-family: IBMPlexSansRegular !important;
  color: #516377 !important;
  font-size: 15px !important;
}

.tableRowView {
  cursor: pointer !important;
  text-decoration: none !important;
}

.itemPaymentMethod::first-letter {
  text-transform: capitalize!important;
}
