.mainView {
  display: flex;
  overflow: unset !important;
}

.calenderMainView {
  flex: 1;
  position: relative;
  /*border-left: 1px solid #0000001f;*/
}

.menuIcon {
  position: absolute;
  top: 32px;
  left: 15px;
  line-height: 1 !important;
}

.filterTitle {
  color: #a8b1bb !important;
  font-family: IBMPlexSansMedium !important;
  font-size: 85% !important;
}

.weekHeaderWeekText {
  text-transform: uppercase !important;
  font-family: IBMPlexSansMedium !important;
  color: #70757a !important;
  font-size: 10px !important;
}

.dayHeaderWeekText {
  text-transform: uppercase !important;
  color: #70757a !important;
  font-size: 20px !important;
}

.weekHeaderDayText {
  text-transform: uppercase !important;
  font-family: IBMPlexSansMedium !important;
  color: #1a73e8 !important;
  font-size: 10px !important;
}

.dayHeaderDayView {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #1a73e8 !important;
  border-radius: 100px !important;
  padding: 1px !important;
  height: 50px !important;
  width: 50px !important;
}

.dayHeaderDayText {
  text-transform: uppercase !important;
  color: #fff !important;
  font-size: 20px !important;
}
