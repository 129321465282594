.cardView {
  background-color: #ffffff !important;
  border-radius: 6px !important;
  box-shadow: 0 2px 14px rgba(38, 60, 85, 0.16);
}

.logoView {
  display: flex !important;
  align-items: flex-start !important;
  margin-top: 2px;
}

.contentText {
  font-family: IBMPlexSansRegular !important;
  color: #666 !important;
  font-size: 15px !important;
  line-height: 24px !important;
}

.gstText {
  font-family: IBMPlexSansBold !important;
  color: #888 !important;
  font-size: 15px !important;
  line-height: 24px !important;
}

.invoiceText {
  font-family: RubikMedium !important;
  color: #555 !important;
  font-size: 21px !important;
}

.invoiceValueText {
  font-family: RubikMedium !important;
  color: #555 !important;
  font-size: 21px !important;
}

.dateText {
  font-family: IBMPlexSansRegular !important;
  color: #666 !important;
  font-size: 15px !important;
}

.dateValueText {
  font-family: IBMPlexSansMedium !important;
  color: #555 !important;
  font-size: 15px !important;
}

.acceptPaymentViaText {
  font-family: IBMPlexSansRegular !important;
  color: #677788 !important;
  font-size: 15px !important;
}

.borderBottomLine {
  border: 1px solid #e9ecee !important;
}

.borderTableTopLine {
  border: 1px solid #eee !important;
}

.patientTitleText {
  font-family: RubikMedium !important;
  color: #555 !important;
  font-size: 16px !important;
}

.patientDataText {
  font-family: IBMPlexSansRegular !important;
  color: #666 !important;
  font-size: 15px !important;
  line-height: 24px !important;
  text-transform: capitalize !important;
}

.itemPriceFieldTitleText {
  font-family: IBMPlexSansRegular !important;
  color: #666 !important;
  font-size: 15px !important;
}

.itemPriceFieldMainView {
  border: 1px solid #dddddd !important;
}

.borderLine {
  border: 1px solid #dddddd !important;
}

.iconView {
  cursor: pointer !important;
}

.subLabelText {
  font-family: IBMPlexSansRegular !important;
  color: #666 !important;
  font-size: 13px !important;
}

.subValueText {
  font-family: RubikMedium !important;
  color: #555 !important;
  font-size: 13px !important;
}

.labelText {
  font-family: IBMPlexSansRegular !important;
  color: #666 !important;
  font-size: 15px !important;
}

.valueText {
  font-family: RubikMedium !important;
  color: #555 !important;
  font-size: 15px !important;
}

.itemTitle::first-letter {
  text-transform: capitalize !important;
}

.noFoundText {
  font-family: IBMPlexSansRegular !important;
  color: #516377 !important;
  font-size: 15px !important;
}

.totalPrice {
  font-family: IBMPlexSansRegular !important;
  color: #516377 !important;
  font-size: 15px !important;
  text-align: center;
}

.consultantDetailText {
  font-family: IBMPlexSansRegular !important;
  color: #666 !important;
  font-size: 12px !important;
}

@media print {
  @page {
    margin-top: 0;
    margin-bottom: 0;
  }
}
