.labelText {
  font-family: IBMPlexSansRegular !important;
  color: #888 !important;
  font-size: 12px !important;
  margin-bottom: 6px !important;
}

.requiredText {
  color: red !important;
  font-family: IBMPlexSansBold !important;
  font-size: 11px !important
}

.errorView {
}

.errorText {
  color: red !important;
  font-size: 12px !important;
  font-family: IBMPlexSansRegular !important;
}
