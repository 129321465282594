.fc .fc-toolbar {
  flex-wrap: wrap !important;
}

.fc .fc-toolbar .fc-prev-button,
.fc .fc-toolbar .fc-next-button {
  display: inline-block !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border-color: rgba(0, 0, 0, 0) !important;
}

.fc .fc-button-primary:hover,
.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  background-color: #5a8dee !important;
  border-color: #5a8dee !important;
  color: #ffffff !important;
}

.fc .fc-toolbar .fc-prev-button:hover,
.fc .fc-toolbar .fc-prev-button:active,
.fc .fc-toolbar .fc-prev-button:focus,
.fc .fc-toolbar .fc-next-button:hover,
.fc .fc-toolbar .fc-next-button:active,
.fc .fc-toolbar .fc-next-button:focus {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.fc .fc-toolbar .fc-prev-button {
  padding-left: 0 !important;
}

.fc .fc-toolbar .fc-button:not(.fc-next-button):not(.fc-prev-button) {
  padding: 0.438rem 1.125rem !important;
}

.fc .fc-toolbar .fc-button:not(.fc-next-button):not(.fc-prev-button):active,
.fc .fc-toolbar .fc-button:not(.fc-next-button):not(.fc-prev-button):focus {
  box-shadow: none !important;
}

.fc-header-toolbar {
  padding-left: 24px;
  padding-right: 24px;
}

.fc-header-toolbar .fc-toolbar-chunk:nth-child(2) {
  flex: 1;
}

.fc-toolbar-title {
  color: #677788 !important;
  font-size: 1.125rem !important;
  font-family: IBMPlexSansRegular !important;
}

.fc .fc-toolbar > * > :not(:first-child) {
  margin-left: 0 !important;
}

.fc .fc-toolbar > * > :first-child {
  margin-right: 0 !important;
}

.fc .fc-toolbar .fc-toolbar-chunk {
  display: flex !important;
  align-items: center !important;
}

.fc .fc-toolbar .fc-button-group .fc-button {
  text-transform: capitalize !important;
  background: #e5edfc;
  border-color: #e5edfc;
  color: #5a8dee;
  font-family: IBMPlexSansRegular !important;
}

.fc .fc-toolbar .fc-button-group + div {
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap !important;
}

.fc .fc-toolbar .fc--button:empty,
.fc .fc-toolbar .fc-toolbar-chunk:empty {
  display: none !important;
}

.fc .fc-toolbar .fc-sidebarToggle-button + div {
  margin-left: 0 !important;
}

.fc .fc-view-harness {
  min-height: 650px !important;
}

.fc .fc-daygrid-event {
  border-radius: 0.25rem !important;
}

.fc .fc-h-event .fc-event-main,
.fc .fc-v-event .fc-event-main {
  color: inherit !important;
}

.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.fc .fc-view-harness .fc-daygrid-body .fc-daygrid-day .fc-daygrid-day-top {
  flex-direction: row !important;
}

.fc
  .fc-view-harness
  .fc-daygrid-body
  .fc-daygrid-day
  .fc-daygrid-day-top
  .fc-daygrid-day-number {
  float: left !important;
  margin-left: 0.5rem !important;
}

.fc .fc-view-harness .fc-event {
  font-size: 0.75rem !important;
  font-family: IBMPlexSansRegular !important;
  padding: 0.25rem 0.5rem !important;
  border-radius: 3px !important;
}

.fc .fc-view-harness .fc-daygrid-event-harness .fc-event.private-event {
  background-color: rgba(0, 0, 0, 0) !important;
  border-color: rgba(0, 0, 0, 0) !important;
}

.fc .fc-view-harness .fc-event .fc-daygrid-event-dot {
  display: none !important;
}

.fc .fc-timegrid .fc-timegrid-divider {
  display: none !important;
}

.fc .fc-daygrid-event-harness + .fc-daygrid-event-harness {
  margin-top: 0.3rem !important;
}

.fc .fc-popover {
  z-index: 1090 !important;
}

.fc .fc-popover .fc-popover-header {
  padding: 0.566rem !important;
}

.fc .fc-timegrid-event-harness .fc-timegrid-event .fc-event-time {
  font-size: 0.813rem !important;
  font-family: IBMPlexSansRegular !important;
}

.fc-theme-standard .fc-list {
  border: 0 !important;
}

.fc .fc-toolbar .fc-prev-button .fc-icon,
.fc .fc-toolbar .fc-next-button .fc-icon {
  color: #677788 !important;
}

.fc .fc-day-other .fc-daygrid-day-top {
  opacity: 1 !important;
  color: #a8b1bb !important;
  font-family: IBMPlexSansRegular !important;
}

.fc table.fc-scrollgrid {
  border-color: #d4d8dd !important;
}

.fc table.fc-scrollgrid .fc-col-header tbody {
  border: none !important;
}

.fc table.fc-scrollgrid .fc-col-header .fc-col-header-cell {
  border-color: #d4d8dd !important;
}

.fc table.fc-scrollgrid td {
  border-color: #d4d8dd !important;
}

/*.fc .private-event .fc-event-time,*/
/*.fc .private-event .fc-event-title {*/
/*  color: #ff5b5c !important;*/
/*}*/

.fc .fc-daygrid-day.fc-day-today,
.fc .fc-day-today {
  background-color: #f2f2f6 !important;
}

.fc .fc-popover {
  box-shadow: 0 0.625rem 1.25rem rgba(147, 158, 170, 0.5) !important;
  border-color: #d4d8dd !important;
}

.fc .fc-popover .fc-popover-header {
  background: #fff !important;
}

.fc .fc-popover .fc-popover-header .fc-popover-close {
  color: #a8b1bb !important;
}

.fc .fc-list {
  border-color: #d4d8dd !important;
}

.fc .fc-list .fc-list-table th {
  border: 0 !important;
  background: #f2f2f6 !important;
}

.fc .fc-list .fc-list-table .fc-list-event {
  cursor: pointer !important;
}

/*.fc .fc-list .fc-list-table .fc-list-event:hover td {*/
/*  background-color: rgba-to-hex(rgba(38, 60, 85, 0.06), #fff) !important;*/
/*}*/

.fc .fc-list .fc-list-table .fc-list-event td {
  border-color: #d4d8dd !important;
}

.fc .fc-list .fc-list-empty {
  background-color: #f2f2f6 !important;
}

.fc table,
.fc tbody,
.fc thead,
.fc tbody td {
  border-color: #d4d8dd !important;
  font-family: IBMPlexSansRegular;
}

.fc-theme-standard .fc-list {
  border-top: 1px solid #d4d8dd !important;
}

.fc-theme-standard .fc-list .fc-list-day-cushion.fc-cell-shaded {
  background: #f2f2f6 !important;
}

@media (max-width: 600px) {
  .fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) {
    margin-top: 1rem !important;
  }

  .fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) {
    margin-left: 2rem !important;
  }
}

.fc-timeGridWeek-view .fc-event:not(.fc-list-event).appointment-active,
.fc-timeGridDay-view .fc-event:not(.fc-list-event).appointment-active,
.fc-listMonth-view .fc-event:not(.fc-list-event).appointment-active {
  background-color: #dff9ec !important;
  color: #33b679 !important;
  border-color: rgba(0, 0, 0, 0);
  border-left: 3px solid #33b679;
}

.fc-timeGridWeek-view .fc-event:not(.fc-list-event).appointment-completed,
.fc-timeGridDay-view .fc-event:not(.fc-list-event).appointment-completed,
.fc-listMonth-view .fc-event:not(.fc-list-event).appointment-completed {
  background-color: #e5edfc !important;
  color: #039be5 !important;
  border-color: rgba(0, 0, 0, 0);
  border-left: 3px solid #039be5;
}

.fc-timeGridWeek-view .fc-event:not(.fc-list-event).appointment-cancelled,
.fc-timeGridDay-view .fc-event:not(.fc-list-event).appointment-cancelled,
.fc-listMonth-view .fc-event:not(.fc-list-event).appointment-cancelled {
  background-color: #ffe5e5 !important;
  color: #f4511e !important;
  border-color: rgba(0, 0, 0, 0);
  border-left: 3px solid #f4511e;
}

.fc-event:not(.fc-list-event) {
  background-color: transparent !important;
  color: #3c4043 !important;
  border-color: rgba(0, 0, 0, 0) !important;
  border-left: 3px solid transparent !important;
}

.appointment-active .fc-list-event-dot {
  border-color: #33b679;
}

.appointment-completed .fc-list-event-dot {
  border-color: #039be5;
}

.appointment-cancelled .fc-list-event-dot {
  border-color: #f4511e;
}

.fc .fc-list .fc-list-table .fc-list-event td {
  font-family: IBMPlexSansBold !important;
}


/*css*/
.fc table {
  font-size: 0.9em !important;
  color: rgb(60,64,67) !important;
}

.fc-dayGridMonth-view thead {
  border-style: hidden !important;
}

.fc-timeGridWeek-view .Appointments_weekHeaderWeekText__JDxNi {
  text-transform: uppercase !important;
  font-family: IBMPlexSansMedium !important;
  font-size: 10px !important;
}

.fc-timeGridWeek-view .fc-day-today .Appointments_weekHeaderWeekText__JDxNi {
  color: #1a73e8 !important;
}

.fc-timeGridWeek-view .Appointments_dayHeaderWeekText__7cnsk {
  text-transform: uppercase !important;
  font-family: IBMPlexSansMedium !important;
  font-size: 20px !important;
}

.fc-timeGridWeek-view .fc-day-today .Appointments_dayHeaderWeekText__7cnsk {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #1a73e8 !important;
  border-radius: 100px !important;
  padding: 1px !important;
  height: 50px !important;
  width: 50px !important;
  color: #fff !important;
}

.fc-timeGridWeek-view .fc-scrollgrid {
  border: none !important;
}

.fc-timeGridDay-view .fc-scrollgrid {
  border: none !important;
}

.fc .fc-col-header-cell-cushion {
  font-size: 0.8em !important;
  color: #70757a !important;
  text-transform: uppercase !important;
  font-family: IBMPlexSansMedium !important;
}

.fc .fc-daygrid-day-top {
  justify-content: center !important;
}

.fc .fc-view-harness .fc-daygrid-body .fc-daygrid-day .fc-daygrid-day-top .fc-daygrid-day-number {
  margin-right: 0.5rem !important;
}

.fc .fc-timegrid-slot-label {
  border: unset !important;
}

.fc-timeGridDay-view .fc-scrollgrid-sync-inner {
  display: flex !important;
}

.fc-timegrid-slot {
  height: 50px !important
}

.largeDot {
  border: 4px solid transparent;
  border-radius: 20px  !important;
  box-sizing: content-box  !important;
  display: inline-block  !important;
  height: 0px  !important;
  width: 0px !important;
  margin-right: 8px !important;
}

.largeDot-appointment-active {
  border: 4px solid #33b679;
  border-radius: 20px  !important;
  box-sizing: content-box  !important;
  display: inline-block  !important;
  height: 0px  !important;
  width: 0px !important;
  margin-right: 8px !important;
}

.largeDot-appointment-completed {
  border: 4px solid #039be5;
  border-radius: 20px  !important;
  box-sizing: content-box  !important;
  display: inline-block  !important;
  height: 0px  !important;
  width: 0px !important;
  margin-right: 8px !important;
}

.largeDot-appointment-cancelled {
  border: 4px solid #f4511e;
  border-radius: 20px  !important;
  box-sizing: content-box  !important;
  display: inline-block  !important;
  height: 0px  !important;
  width: 0px !important;
  margin-right: 8px !important;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 12px!important;
}

.fc-v-event .fc-event-title-container {
  font-family: IBMPlexSansSemiBold !important;
}
