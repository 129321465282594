.labelText {
    font-family: IBMPlexSansRegular !important;
    color: #888 !important;
    font-size: 12px !important;
    margin-bottom: 6px !important;
}

.requiredText {
    color: red !important;
    font-family: IBMPlexSansBold !important;
    font-size: 11px !important
}

.errorBorderStyle {
    border: 1px solid red !important;
}

.borderStyle {
    border: 1px solid #CCC !important;
}

.errorView {
}

.errorText {
    font-family: IBMPlexSansRegular !important;
    color: red !important;
    font-size: 12px !important;
}
