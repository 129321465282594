.cardView {
  background-color: #ffffff !important;
  border-radius: 2px;
  z-index: 1000;
  box-shadow: 0 2px 14px rgba(38, 60, 85, 0.16);
}

.titleText {
  font-family: RubikSemiBold !important;
  text-align: center !important;
  font-size: 21px !important;
  color: #516377 !important;
}

.requiredText {
  color: red !important;
  font-family: IBMPlexSansBold !important;
  font-size: 12px !important
}

.customerToPayTitleText {
  font-family: IBMPlexSansMedium !important;
  font-size: 15px !important;
  color: #000 !important;
}

.customerToPayValueText {
  font-family: IBMPlexSansMedium !important;
  font-size: 15px !important;
  color: #000 !important;
}

.tableView {
  overflow-x: auto !important;
}

tbody .dbdAmountValueText {
  color: red !important;
  font-size: 0.85rem !important;
  font-family: IBMPlexSansBold !important;
}

tbody .valueText {
  color: #677788 !important;
  font-size: 0.85rem !important;
  font-family: IBMPlexSansBold !important;
}
